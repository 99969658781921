@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: hsl(0, 0%, 100%);
    --foreground: hsl(240, 10%, 3.9%);
    --card: hsl(0, 0%, 100%);
    --card-foreground: hsl(240, 10%, 3.9%);
    --popover: hsl(0, 0%, 100%);
    --popover-foreground: hsl(240, 10%, 3.9%);
    --primary: hsl(240, 5.9%, 10%);
    --primary-foreground: hsl(0, 0%, 98%);
    --secondary: hsl(240, 4.8%, 95.9%);
    --secondary-foreground: hsl(240, 5.9%, 10%);
    --muted: hsl(240, 4.8%, 95.9%);
    --muted-foreground: hsl(240, 3.8%, 46.1%);
    --accent: hsl(240, 4.8%, 95.9%);
    --accent-foreground: hsl(240, 5.9%, 10%);
    --destructive: hsl(0, 84.2%, 60.2%);
    --destructive-foreground: hsl(0, 0%, 98%);
    --border: hsl(240, 5.9%, 90%);
    --input: hsl(240, 5.9%, 90%);
    --ring: hsl(240, 5.9%, 10%);
    --radius: 0.5rem;
    --chart-1: hsl(12, 76%, 61%);
    --chart-2: hsl(173, 58%, 39%);
    --chart-3: hsl(197, 37%, 24%);
    --chart-4: hsl(43, 74%, 66%);
    --chart-5: hsl(27, 87%, 67%);
  }

  .dark {
    --background: hsl(240, 10%, 3.9%);
    --foreground: hsl(0, 0%, 98%);
    --card: hsl(240, 10%, 3.9%);
    --card-foreground: hsl(0, 0%, 98%);
    --popover: hsl(240, 10%, 3.9%);
    --popover-foreground: hsl(0, 0%, 98%);
    --primary: hsl(0, 0%, 98%);
    --primary-foreground: hsl(240, 5.9%, 10%);
    --secondary: hsl(240, 3.7%, 15.9%);
    --secondary-foreground: hsl(0, 0%, 98%);
    --muted: hsl(240, 3.7%, 15.9%);
    --muted-foreground: hsl(240, 5%, 64.9%);
    --accent: hsl(240, 3.7%, 15.9%);
    --accent-foreground: hsl(0, 0%, 98%);
    --destructive: hsl(0, 62.8%, 30.6%);
    --destructive-foreground: hsl(0, 0%, 98%);
    --border: hsl(240, 3.7%, 15.9%);
    --input: hsl(240, 3.7%, 15.9%);
    --ring: hsl(240, 4.9%, 83.9%);
    --chart-1: hsl(220, 70%, 50%);
    --chart-2: hsl(160, 60%, 45%);
    --chart-3: hsl(30, 80%, 55%);
    --chart-4: hsl(280, 65%, 60%);
    --chart-5: hsl(340, 75%, 55%);
  }


  body {
    @apply bg-background text-foreground;
    font-family: Helvetica, Arial, sans-serif;
  }
}

@layer utilities {
  .bg-background {
    background-color: var(--background);
  }

  .text-foreground {
    color: var(--foreground);
  }

  .wrapper {
    @apply max-w-[1440px] lg:mx-auto w-full;
  }

  .flex-start {
    @apply flex justify-start items-center;
  }

  .flex-center {
    @apply flex justify-center items-center;
  }

  .flex-between {
    @apply flex justify-between items-center;
  }

  .h1-bold {
    @apply font-bold text-3xl lg:text-4xl;
  }

  .h2-bold {
    @apply font-bold text-2xl lg:text-3xl;
  }

  .h3-bold {
    @apply font-bold text-xl lg:text-2xl;
  }
}
